import React, { useEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../../components/Layout'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../specific/constants/menu'
import { locale, t } from '../../i18n'
import route from '../../utils/route'
import RequestButton from '../../../shared/catalogo/producto/ficha/RequestButton'
import CatalogoFicha from '../../../shared/catalogo/producto/ficha/CatalogoFicha'
import { initiaBateriaOptions } from '../../components/cesta/cestaItem/CestaItem'
import _ from 'lodash'
import { loadCategoriaIcon } from '../../../shared/catalogo/producto/ficha/gallery/utils'
import ComprarProductoCTA from '../../../shared/catalogo/producto/ficha/cta/ComprarProductoCTA'
import Recogida from '../../../shared/catalogo/producto/ficha/cta/Recogida'
import { generateAceiteTags } from '../../../coche/aceites/catalogo/generateAceiteTags'
import { Documentos } from '../../../shared/catalogo/producto/ficha/documentos/Documentos'
import useUpdatePromocion from '../../helpers/customHooks/useUpdatePromocion'
import { getFetchFunction } from '../../../shared/catalogo/producto/ficha/promocion/getFetchFunction'
import { connect } from 'react-redux'
import { IAppState } from '../../context/context'
import { selectPostCode } from '../../context/selectors/userSelectors'
import { ProductInfo } from '../../context/actions/cestaActions'
import WhiteBoxContainer from '../../../shared/components/WhiteBoxContainer'
import RequestTyre from '../../components/RequestTyre/RequestTyre'
import { CatalogoProductInfo } from '../../../shared/catalogo/producto/ficha/CatalogoProductInfo'
import { productoActivo } from '../../components/FichasNeumaticos/utils'
import Modal from '../../../shared/components/Modal/Modal'

const FichaAceitePage = ({
  data: { aceiteInfo, seoData },
  postCode,
}: PageProps<Queries.FichaAceitePageQuery>) => {
  useSelectedMenu(MenuPrincipal.COCHE)
  const [successMessage, setSuccessMessage] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedProducto, setSelectedProducto] = useState(
    null as ProductInfo | null
  )
  const isActive = true
  const [icon, setIcon] = useState(null)

  useEffect(() => {
    if (!aceiteInfo) {
      return
    }
    loadCategoriaIcon(aceiteInfo.producto.categoria).then(setIcon)
  }, [aceiteInfo])

  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const updatedAceiteInfo = {
    ...aceiteInfo,
    producto: {
      ...aceiteInfo.producto,
      [`descripcion_${locale}`]: aceiteInfo.cantidad,
      disponibilidad: productoActivo(aceiteInfo.producto),
    },
  }

  const catalogoProductInfo: CatalogoProductInfo = {
    ...updatedAceiteInfo,
    nombre_producto: aceiteInfo[`nombre_producto_${locale}`],
    estaciones: icon ? [icon] : [],
    productInfo: {
      id: aceiteInfo.producto.id_navision,
      type: 'aceite',
    },
  }

  const updatedPromotion = useUpdatePromocion({
    id_site_marca_aceite: aceiteInfo.id_site_marca_aceite,
    postCode,
    fetchFunction: getFetchFunction('aceite'),
  })

  const CTAButton = isActive ? (
    <ComprarProductoCTA
      catalogoProductInfo={catalogoProductInfo}
      options={initiaBateriaOptions}
      montaje={<Recogida servicios={aceiteInfo.producto.servicio_montaje} />}
      bannerPromocion={updatedPromotion}
    />
  ) : (
    <section>
      <WhiteBoxContainer className={'yellow'}>
        <RequestButton
          productInfo={catalogoProductInfo.productInfo}
          openModal={setShowModal}
          selectProduct={setSelectedProducto}
          flexDirection={'column'}></RequestButton>
      </WhiteBoxContainer>
    </section>
  )

  return (
    <Layout
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        { display: t('bread_crumb.coche'), link: route('coche.index') },
        {
          display: t('bread_crumb.aceites'),
          link: route('aceites.index'),
        },
      ]}
      selector={false}>
      <CatalogoFicha
        catalogoProductInfo={catalogoProductInfo}
        specifications={[
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.marca'
            ),
            value: aceiteInfo.marca.nombre,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.modelo'
            ),
            value: _.startCase(
              _.toLower(aceiteInfo[`nombre_producto_${locale}`])
            ),
          },
          {
            label: t('aceites.ficha.tipo'),
            value: aceiteInfo.tipo,
          },
          {
            label: t('aceites.ficha.litros'),
            value: aceiteInfo.cantidad,
          },
          {
            label: t('aceites.ficha.viscosidad'),
            value: aceiteInfo.viscosidad,
          },
          {
            label: t('aceites.ficha.homologacion'),
            value: aceiteInfo.homologacion,
          },
        ]}
        cta={CTAButton}
        tags={generateAceiteTags(aceiteInfo)}
        documentos={<Documentos documents={aceiteInfo.documentos} />}
      />
      <Modal
        title={t('landing_neumaticos.neumatico_item.disponibilidad')}
        closeModal={closeModal}
        showModal={showModal}>
        <RequestTyre
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedNeumatico={selectedProducto}
          closeModal={closeModal}
        />
      </Modal>
    </Layout>
  )
}

export const pageQuery = graphql`
  query FichaAceitePage($idSiteProductosAceites: Int!, $url: String!) {
    aceiteInfo: aceite(
      id_site_productos_aceites: { eq: $idSiteProductosAceites }
    ) {
      ...aceiteInfo
      ...productoAceiteInfo
      ...aceiteImagen
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
const mapStateToProps = (state: IAppState) => {
  return {
    postCode: selectPostCode(state),
  }
}
export default connect(mapStateToProps, null)(FichaAceitePage)
