import { Tag } from '../../../shared/catalogo/producto/ficha/Tags/Tags'
import { AceitesInfo } from './api/aceiteInfo'
import { t } from '../../../common/i18n'

export const generateAceiteTags = (aceite: AceitesInfo): Tag[] => {
  return [
    aceite.especificaciones && {
      label: aceite.especificaciones,
      tooltip: t('aceites.ficha.tags.tooltip_especificaciones', {
        especificaciones: aceite.especificaciones,
      }),
    },
    aceite.cantidad && {
      label: aceite.cantidad,
      tooltip: t('aceites.ficha.tags.tooltip_cantiad', {
        cantidad: aceite.cantidad,
      }),
    },
    aceite.viscosidad && {
      label: aceite.viscosidad,
      tooltip: t('aceites.ficha.tags.tooltip_viscosidad', {
        viscosidad: aceite.viscosidad,
      }),
    },
    aceite.homologacion && {
      label: aceite.homologacion,
      tooltip: t('aceites.ficha.tags.tooltip_homologacion', {
        homologacion: aceite.homologacion,
      }),
    },
  ].filter(Boolean)
}
