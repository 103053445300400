import React from 'react'
import styles from './montaje.module.scss'
import RecogidaIcon from './assets/recogida_icon.svg'
import { ServiciosMontaje } from '../../../../../common/components/FichasNeumaticos/types'
import { intl, t } from '../../../../../common/i18n'

interface Props {
  servicios?: ServiciosMontaje
}

const Recogida: React.FC<Props> = ({ servicios }) => {
  if (!servicios || servicios.length === 0) {
    return null
  }

  const validServicios = servicios.filter(
    (servicio) => servicio !== null && servicio !== undefined
  )

  if (validServicios.length === 0) {
    return null
  }

  const precio = Math.min(...validServicios.map((servicio) => servicio.precio))
  const precioTexto =
    precio === 0
      ? t('catalogo.producto.ficha.gratis')
      : intl.formatNumber(precio, {
          style: 'currency',
          currency: 'EUR',
        })

  return (
    <div className={styles.wrapper}>
      <div className={styles.price_header}>
        <RecogidaIcon />
        <span className={styles.price_label}>
          &nbsp;{t('catalogo.producto.ficha.recogida')}
        </span>
        <span className={styles.price_text}>
          &nbsp;
          {precioTexto}
        </span>
      </div>
    </div>
  )
}

export default Recogida
